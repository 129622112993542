import React, {Component, useEffect, useState} from 'react';
import {
    Button, Flex,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText, Input, toast, useToast
} from "@chakra-ui/react";
import {NavLink, useHistory} from "react-router-dom";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import ProjectApi from '../../../api/project'
import {useAuth} from "../../../auth-context/auth.context";

const ProjectForm = () => {
    const [title, setTitle] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [buttonText, setButtonText] = useState("Save");
    const { user } = useAuth();
    const history = useHistory();
    const toast = useToast();

    const handelSubmit = async () => {
        if (!title) {
            toast({
                title: 'Error!',
                description: 'Title is required',
                status: 'error',
                duration: 2000,
                position:'top',
                isClosable: true,
            })
        }
        if (title) {
            const config = {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
            };
            const addNewProjectResponse = await ProjectApi.Add({title:title}, config);

            if (!addNewProjectResponse?.data?.success) {
                toast({
                    title: 'Error!',
                    description: addNewProjectResponse?.data?.msg,
                    status: 'error',
                    position:'top',
                    duration: 2000,
                    isClosable: true,
                })
            }else {
                toast({
                    title: 'Success!',
                    description: addNewProjectResponse?.data?.msg,
                    status: 'success',
                    duration: 2000,
                    position:'top',
                    isClosable: true,
                })
                setTimeout(()=>{
                    history.push("/admin/projects");
                },2200)
            }
        }
    }

    return (
        <>
            <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
                <Card
                    my="22px"
                    overflowX={{ sm: "scroll", xl: "hidden" }}
                >

                    <CardHeader p="6px 0px 22px 0px" spacing='4'>
                        Add Project
                    </CardHeader>
                    <CardBody>
                        <Flex direction="column">
                            <FormControl isRequired>
                                <FormLabel>Title</FormLabel>
                                <Input value={title} onChange={(e)=> {
                                    setError('')
                                    setTitle(e.target.value)
                                }} placeholder='Title' />
                            </FormControl>
                            <FormControl>
                                <Button
                                    fontSize="10px"
                                    type="submit"
                                    bg="red.500"
                                    mb="20px"
                                    color="white"
                                    mt="20px"
                                    _hover={{
                                        bg: "red.500",
                                    }}
                                    _active={{
                                        bg: "red.400",
                                    }}
                                    onClick={handelSubmit}
                                >
                                    {buttonText}
                                </Button>
                            </FormControl>
                        </Flex>
                    </CardBody>
                </Card>
            </Flex>
        </>
    );
}

export default ProjectForm;

import React from "react";
import {
    Tr,
    Td,
    Flex,
    Text,
    Progress,
    Icon,
    Button,
    useColorModeValue, toast, useToast, Badge, Switch, Stack,
} from "@chakra-ui/react";
import {FaEllipsisV, FaPencilAlt, FaTrashAlt} from "react-icons/fa";
import ProjectApi from "../../api/project";
import {useAuth} from "../../auth-context/auth.context";
import {NavLink, useHistory, useLocation} from "react-router-dom";

function DashboardTableRow(props) {
  const { name, status, projectId, onDelete } = props;
  const textColor = useColorModeValue("gray.700", "white");
    const toast = useToast();
    const { user } = useAuth();
    const history = useHistory();

    const handleDelete = async (projectId) => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user?.token}`,
            },
        };

        let response = await ProjectApi.Delete(projectId, config);
        if (!response?.data?.success) {
          toast({
            title: 'Error!',
            description: response?.data?.message,
            status: 'error',
            position:'top',
            duration: 2000,
            isClosable: true,
          })
        } else if (response?.data?.success) {
            onDelete(true)
          toast({
            title: 'Success!',
            description: response?.data?.message,
            status: 'success',
            duration: 2000,
            position:'top',
            isClosable: true,
          })
        }
    }
    const handleEditRequest = (projectId) => {
        console.log(projectId, 'projectId')
        history.push("/admin/projects-edit/" + projectId);
    }

    const handleStatusUpdate = async (projectId, status, name) => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user?.token}`,
            },
        };
        console.log(typeof  status, 'type status');
        console.log(status, 'status')
        let statusChange = 1;
        if (status === 1) {
            statusChange = 0
        }
        console.log(statusChange, 'status after')
        const addNewProjectResponse = await ProjectApi.UpdateProject(projectId, {status:statusChange, title:name}, config);

        if (!addNewProjectResponse?.data?.success) {
            toast({
                title: 'Error!',
                description: addNewProjectResponse?.data?.message,
                status: 'error',
                position:'top',
                duration: 2000,
                isClosable: true,
            })
        }else {
            onDelete(true)
            toast({
                title: 'Success!',
                description: 'Status updated successfully',
                status: 'success',
                duration: 2000,
                position:'top',
                isClosable: true,
            })
        }
    }
    let isCheckedSwtich = Number(status) === 1 ? 'isChecked' : '';

    return (
    <Tr>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {name}
          </Text>
        </Flex>
      </Td>
      <Td>
          <Stack
              onChange={(e)=>{
                  handleStatusUpdate(projectId, Number(status),name)
              }}
              align='center' direction='row'>
              <Switch isChecked={Number(status) === 1} colorScheme='red' size='lg' />
          </Stack>
      </Td>
      <Td>
          <Button
              p="0px"
              bg="transparent"
              mb={{ sm: "10px", md: "0px" }}
              me={{ md: "12px" }}
              onClick={()=> {
                  handleDelete(projectId)
              }}
          >
              <Flex
                  color="red.500" cursor="pointer" align="center" p="12px">
                  <Icon as={FaTrashAlt} me="4px" />
              </Flex>
          </Button>
          <Button
              onClick={()=> {
                  handleEditRequest(projectId)
              }}
              p="0px" bg="transparent">
              <Flex color={textColor} cursor="pointer" align="center" p="12px">
                  <Icon as={FaPencilAlt} me="4px" />
              </Flex>
          </Button>
      </Td>
    </Tr>
  );
}

export default DashboardTableRow;

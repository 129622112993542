// import
import Dashboard from "views/Dashboard/Dashboard.js";
import Billing from "views/Dashboard/Billing.js";
import Profile from "views/Dashboard/Profile.js";
import SignOut from "views/Pages/SignOut.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import {MdAccountBalance, MdCalendarViewMonth} from "react-icons/md";
import {SettingsIcon} from "@chakra-ui/icons";
// projects
import Listing from "./views/Dashboard/Projects/Listing";
import ProjectForm from "./views/Dashboard/Projects/ProjectForm";
import ProjectEditForm from "./views/Dashboard/Projects/ProjectEditForm";
// unit defining
import Plots from "./views/Dashboard/Plots/Plots";
import PlotAddForm from "./views/Dashboard/Plots/AddForm";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
    parent:""
  },
  {
    path: "/projects",
    name: "Projects",
    rtlName: "Projects",
    icon: <StatsIcon color="inherit" />,
    component: Listing,
    layout: "/admin",
    parent:""
  },
  {
    path: "/projects-create",
    name: "Project Create",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: ProjectForm,
    layout: "/admin",
    hide:true,
    parent:""
  },
  {
    path: "/projects-edit/:id",
    name: "Project Edit",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: ProjectEditForm,
    layout: "/admin",
    hide:true,
    parent:""
  },
  {
    path: "/plots",
    name: "Plots",
    rtlName: "Plots",
    icon: <StatsIcon color="inherit" />,
    component: Plots,
    hide:true,
    layout: "/admin",
    parent:""
  },
  {
    path: "/plots-create",
    name: "Unit Defining",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: PlotAddForm,
    layout: "/admin",
    hide:true,
    parent:""
  },
  {
    path: "/plots-edit/:id",
    name: "Unit Edit",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: ProjectEditForm,
    layout: "/admin",
    hide:true,
    parent:""
  },
  {
    path: "/billing",
    name: "Billing",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color="inherit" />,
    component: Billing,
    layout: "/admin",
    parent:""
  },
  {
    name: "Account",
    category: "Account",
    rtlName: "صفحات",
    state: "pageCollapse",
    icon: <MdAccountBalance color="inherit" />,
    views: [
      {
        path: "/user-profile",
        name: "Profile",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color="inherit" />,
        component: Billing,
        layout: "/admin",
        parent: "",
      }
    ],
  },
  {
    name: "Property",
    category: "Property",
    rtlName: "صفحات",
    state: "pageCollapse",
    icon: <MdCalendarViewMonth color="inherit" />,
    views: [
      {
        path: "/Plots",
        name: "Unit Defining",
        rtlName: "Plots",
        icon: <PersonIcon color="inherit" />,
        component: Plots,
        layout: "/admin",
        parent: "",
      },
    ],
  },
  {
    name: "Settings",
    category: "Settings",
    rtlName: "صفحات",
    state: "pageCollapse",
    icon: <SettingsIcon color="inherit" />,
    views: [
      {
        path: "/profile",
        name: "Profile",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color="inherit" />,
        component: Profile,
        layout: "/admin",
        parent: "Settings",
      },
    ],
  },
  {
    path: "/signout",
    name: "Logout",
    rtlName: "لوحة القيادة",
    icon: <RocketIcon color="inherit" />,
    component: SignOut,
    layout: "/auth",
    parent: "",
  }
];
export default dashRoutes;

import React, {useEffect, useState} from "react";
// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex, Icon, Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead, toast,
  Tr,
  useColorModeValue, useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TablesTableRow from "components/Tables/TablesTableRow";
import { tablesProjectData, tablesTableData } from "variables/general";
import ProjectApi from "../../../api/project";
import {useAuth} from "../../../auth-context/auth.context";
import {FaCube, FaPenFancy, FaPlus} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import ProfileBgImage from "../../../assets/img/ProfileBackground.png";
import avatar4 from "../../../assets/img/avatars/avatar4.png";
import {IoDocumentsSharp} from "react-icons/io5";

function Listing() {
  const textColor = useColorModeValue("gray.700", "white");
  const [projects, setProjects] = useState({});
  const [errors, setErrors] = useState('');
  const [deleteData, setDeleteData] = useState(false);
  const { user } = useAuth();

  const fetchProjectData = async () => {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
    };
    let response = await ProjectApi.List(config);
    if (response.data && response.data.success === false) {
      //return setErrors(response.data.msg);
    }

    setProjects(response?.data?.projects);
  }

  useEffect(() => {
    fetchProjectData();
    setDeleteData(false)
  },[deleteData])

  return (
      <>
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
          <div >
            <NavLink to="/admin/projects-create">
              <Button
                  fontSize="15px"
                  type="submit"
                  bg="red.300"
                  w="10%"
                  h="45"
                  mb="20px"
                  color="white"
                  mt="20px"
                  _hover={{
                    bg: "red.500",
                  }}
                  _active={{
                    bg: "red.400",
                  }}
              >
                Add Project
              </Button>
            </NavLink>
          </div>
          <Card
              my="22px"
              overflowX={{ sm: "scroll", xl: "hidden" }}
          >
            <CardHeader p="6px 0px 22px 0px" spacing='4'>

            </CardHeader>
            <CardBody>
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px">
                    <Th pl="0px" color="gray.400">
                      Title
                    </Th>
                    <Th color="gray.400">Status</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {projects?.length > 0
                      ? (
                          <>
                            {projects?.map((row) => {
                              return (
                                  <TablesProjectRow
                                      name={row?.title}
                                      status={row?.status}
                                      projectId={row?.id}
                                      onDelete={setDeleteData}
                                  />
                              )
                            })}
                          </>
                      )
                      : (
                          <tr>
                            <td colSpan={3} fontSize="15px">No data Found</td>
                          </tr>
                      )
                  }
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        </Flex>
      </>
  );
}

export default Listing;

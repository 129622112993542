import React, {Component, useEffect, useState} from 'react';
import {
    Button, Flex,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText, Input, toast, useToast, Grid, GridItem, Text, Container, Textarea, Select, Box, Center, Divider
} from "@chakra-ui/react";
import {NavLink, useHistory} from "react-router-dom";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import PlotApi from '../../../api/plot'
import ProjectApi from '../../../api/project'
import {useAuth} from "../../../auth-context/auth.context";
import * as yup from 'yup';

const AddForm = () => {
    const [title, setTitle] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [projects, setProjects] = useState([]);
    const [buttonText, setButtonText] = useState("Save");
    const { user } = useAuth();
    const history = useHistory();
    const toast = useToast();

    const [projectID, setProjectID] = useState(null);
    const [projectName, setProjectName] = useState(null);
    const [address, setAddress] = useState(null);
    const [totalLandArea, setTotalLandArea] = useState(null);
    const [saleAbleLandArea, setSaleAbleLandArea] = useState(null);
    const [measurePercentage, setMeasurePercentage] = useState(null);
    const [measureUnit, setMeasureUnit] = useState(null);
    const [unitType, setUnitType] = useState(null);
    const [unitNumber, setUnitNumber] = useState(null);
    const [location, setLocation] = useState(null);
    const [srNumber, setSrNumber] = useState(null);
    const [squareNumber, setSquareNumber] = useState(null);
    const [remarks, setRemarks] = useState(null);
    const [level, setLevel] = useState(null);
    const [packageUnit, setPackage] = useState(null);
    const [perSrFeet, setPerSrFeet] = useState('');
    const [statusRemarkes, setStatusRemarkes] = useState('');
    const [southFeet, setSouthFeet] = useState('');
    const [southSrFeet, setSouthSrFeet] = useState(0);
    const [northFeet, setNorthFeet] = useState(0);
    const [northSrFeet, setNorthSrFeet] = useState(0);
    const [eastSrFeet, setEastSrFeet] = useState(0);
    const [eastFeet, setEastFeet] = useState(0);
    const [westSrFeet, setWestSrFeet] = useState(0);
    const [westFeet, setWestFeet] = useState(0);
    const [merlaSize, setMerlaSize] = useState(0);
    const [mnlSize, setMnlSize] = useState(0);
    const [measurementType, setMeasurementType] = useState('marla');
    const [srFeet, setSrFeet] = useState('');

    const [errors, setErrors] = useState({});

    const validateInputs = () => {
        const newErrors = {};

        if (!projectID) {
            newErrors.projectID = 'Project is required.';
        }

        if (!address) {
            newErrors.address = 'Address is required.';
        }


        if (!measureUnit) {
            newErrors.measureUnit = 'Measure Unit is required.';
        }

        if (!unitType) {
            newErrors.unitType = 'Unit Type is required.';
        }

        if (!unitNumber) {
            newErrors.unitNumber = 'Unit Number is required.';
        }

        if (!location) {
            newErrors.location = 'Location is required.';
        }

        if (!srNumber) {
            newErrors.srNumber = 'SR Number is required.';
        }

        if (!squareNumber) {
            newErrors.squareNumber = 'Square Number is required.';
        }


        if (!level) {
            newErrors.level = 'Level is required.';
        }

        if (!merlaSize) {
            newErrors.merlaSize = 'Land Size is required.';
        }


        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };


    const handelSubmit = async () => {
        const isValid = validateInputs();
        if (!isValid) {
            toast({
                title: 'Error!',
                description: 'Fill all data',
                status: 'error',
                duration: 2000,
                position:'top',
                isClosable: true,
            })
        }
        if (isValid) {
            const config = {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
            };
            const addNewProjectResponse = await PlotApi.Add({title:title}, config);

            if (!addNewProjectResponse?.data?.success) {
                toast({
                    title: 'Error!',
                    description: addNewProjectResponse?.data?.msg,
                    status: 'error',
                    position:'top',
                    duration: 2000,
                    isClosable: true,
                })
            }else {
                toast({
                    title: 'Success!',
                    description: addNewProjectResponse?.data?.msg,
                    status: 'success',
                    duration: 2000,
                    position:'top',
                    isClosable: true,
                })
                setTimeout(()=>{
                    history.push("/admin/projects");
                },2200)
            }
        }
    }

    const handleChange = (e) => {
        setError('');
        setTitle(e.target.value);
    };


    const fetchProjectData = async () => {
        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user?.token}`,
            },
        };
        let response = await ProjectApi.List(config);
        if (response.data && response.data.success === false) {
            //return setErrors(response.data.msg);
        }

        setProjects(response?.data?.projects);
    }

    const calLandSizeBySrFeet = () => {
        let totalArea = parseFloat(southSrFeet) + parseFloat(westSrFeet) + parseFloat(eastSrFeet) + parseFloat(northSrFeet);
        setSrFeet(totalArea)
    }
    const calLandSize = () => {
        let totalAreaInMarla = parseFloat(srFeet) / parseFloat(perSrFeet);
        setMerlaSize(totalAreaInMarla)
    }

    useEffect(() => {
        fetchProjectData();
    },[])

    useEffect(() => {
        calLandSizeBySrFeet();
    },[southSrFeet, northSrFeet, eastSrFeet, westSrFeet])

    useEffect(() => {
        calLandSize();
    },[perSrFeet])

    return (
        <>
            <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
                <form onSubmit={handelSubmit}>
                    <Card my={{ base: "10px", sm: "22px" }} overflowX={{ sm: "scroll", xl: "hidden" }}>
                        <CardHeader p={{ base: "4px 0px 12px 0px", md: "6px 0px 22px 0px" }}>
                            <Text fontSize="2xl" fontWeight="600" color="red.500" alignSelf="center">
                                Project Detail
                            </Text>
                        </CardHeader>
                        <CardBody>
                            <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap="20px">
                                <Container >
                                    <Container display="flex" justifyContent="space-between">
                                        <FormControl isRequired>
                                            <FormLabel>Project</FormLabel>
                                            <Input
                                                id="projectTitle"
                                                name="projectTitle"
                                                value={projectName}
                                                disabled={true}
                                            />
                                        </FormControl>
                                    </Container>
                                    <Container display="flex" justifyContent="space-between">
                                        <FormControl>
                                            <Text mb="8px">Address</Text>
                                            <Textarea
                                                id="address"
                                                name="address"
                                                onChange={(e)=>{
                                                    setAddress(e.target.value)
                                                }}
                                            />
                                        </FormControl>
                                    </Container>
                                </Container>
                                <Container>
                                    <Container >
                                        <Flex
                                            direction={{ base: "column", md: "row" }}
                                            justifyContent={{ base: "flex-start", md: "space-between" }}
                                            gap="2"
                                        >
                                            <FormControl isRequired>
                                                <FormLabel>Brand</FormLabel>
                                                <Box>
                                                    <Select
                                                        onChange={(e) => {
                                                            let projectData = e.target.value;
                                                            projectData = projectData.split('-');
                                                            setProjectID(projectData[0]);
                                                            setProjectName(projectData[1]);
                                                        }}
                                                        placeholder="Select Project"
                                                    >
                                                        {projects ? projects?.map((project) => (
                                                            <option key={project?.id} value={project?.id + '-' + project?.title}>
                                                                {project?.title}
                                                            </option>
                                                        )) : ('')}
                                                    </Select>
                                                </Box>
                                            </FormControl>
                                        </Flex>
                                    </Container>
                                    <Container>
                                        <Flex
                                            direction={{ base: "column", md: "row" }}
                                            justifyContent={{ base: "flex-start", md: "space-between" }}
                                            gap="2"
                                        >
                                            <FormControl >
                                                <FormLabel>Total Land Area</FormLabel>
                                                <Input
                                                    onChange={(e)=>{
                                                        setTotalLandArea(e.target.value)
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl >
                                                <FormLabel>Saleable Land Area</FormLabel>
                                                <Input
                                                    onChange={(e)=>{
                                                        setSaleAbleLandArea(e.target.value)
                                                    }}
                                                />
                                            </FormControl>
                                        </Flex>

                                        <Flex
                                            direction={{ base: "column", md: "row" }}
                                            justifyContent={{ base: "flex-start", md: "space-between" }}
                                            gap="2"
                                        >
                                            <FormControl >
                                                <FormLabel>Measure unit</FormLabel>
                                                <Box>
                                                    <Select
                                                        onChange={(e)=>{
                                                            setMeasureUnit(e.target.value)
                                                        }}
                                                    >
                                                        <option value="sqft">Sqft</option>
                                                    </Select>
                                                </Box>
                                            </FormControl>
                                            <FormControl >
                                                <FormLabel>%</FormLabel>
                                                <Input
                                                    onChange={(e)=>{
                                                        setMeasurePercentage(e.target.value)
                                                    }}
                                                />
                                            </FormControl>
                                        </Flex>
                                    </Container>
                                </Container>
                            </Grid>
                        </CardBody>
                    </Card>
                    <Card my={{ base: "10px", sm: "22px" }} overflowX={{ sm: "scroll", xl: "hidden" }}>
                        <CardHeader p={{ base: "4px 0px 12px 0px", md: "6px 0px 22px 0px" }}>
                            <Text
                                fontSize="2xl"
                                fontWeight="600"
                                color="red.500"
                                alignSelf="center"
                            >
                                Unit Detail
                            </Text>
                        </CardHeader>
                        <CardBody>
                            <Box>
                                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)", xl: "repeat(3, 1fr)" }} gap="20px">
                                    <Container >
                                        <Container display="flex" justifyContent="space-between">
                                            <FormControl isRequired>
                                                <FormLabel>Unit Type</FormLabel>
                                                <Box>
                                                    <Select
                                                    onChange={(e)=>{
                                                        setUnitType(e.target.value)
                                                    }}>
                                                        <option value=''>Select</option>
                                                        <option value='apartment'>Apartment</option>
                                                        <option value='com-plot'>Com. Plot</option>
                                                        <option value='land-plot'>land. Plot</option>
                                                        <option value='office'>Office</option>
                                                        <option value='res-plot'>Res. Plot</option>
                                                        <option value='shop'>Shop</option>
                                                        <option value='unit'>Unit</option>
                                                    </Select>
                                                </Box>
                                            </FormControl>
                                        </Container>
                                        <Container display="flex" justifyContent="space-between">
                                            <FormControl>
                                                <Text mb='8px'>Unit #</Text>
                                                <Input
                                                    onChange={(e)=>{
                                                        setUnitNumber(e.target.value)
                                                    }}
                                                    size='sm'
                                                />
                                            </FormControl>
                                        </Container>
                                        <Container display="flex" justifyContent="space-between">
                                            <FormControl >
                                                <FormLabel>Location</FormLabel>
                                                <Box>
                                                    <Select
                                                        onChange={(e)=>{
                                                            setLocation(e.target.value)
                                                        }}
                                                    >
                                                        <option value=''>Select</option>
                                                        <option value='boulevard'>Boulevard</option>
                                                        <option value='corner'>Corner</option>
                                                        <option value='normal'>Normal</option>
                                                        <option value='others'>Others</option>
                                                    </Select>
                                                </Box>
                                            </FormControl>
                                        </Container>
                                        <Center height='50px'>
                                            <Divider orientation='vertical' />
                                        </Center>
                                        <Container display="flex" justifyContent="space-between">
                                            <FormControl>
                                                <Text mb='8px'>Sr #</Text>
                                                <Input
                                                    onChange={(e)=>{
                                                        setSrNumber(e.target.value)
                                                    }}
                                                    size='sm'
                                                />
                                            </FormControl>
                                        </Container>
                                        <Container>
                                            <Flex direction={{ base: "column", md: "row" }} justifyContent="space-between" gap="2" alignItems="center">
                                                <FormControl>
                                                    <Text mb='8px'>Square #</Text>
                                                    <Input
                                                        onChange={(e)=>{
                                                            setSquareNumber(e.target.value)
                                                        }}
                                                        size='sm'
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <Text mb='8px'>Khasra #</Text>
                                                    <Input
                                                        onChange={(e)=>{
                                                            setKhasraNumber(e.target.value)
                                                        }}
                                                        size='sm'
                                                    />
                                                </FormControl>
                                            </Flex>
                                        </Container>
                                        <Container display="flex" justifyContent="space-between">
                                            <FormControl>
                                                <Text mb='8px'>Remarks</Text>
                                                <Input
                                                    onChange={(e)=>{
                                                        setRemarks(e.target.value)
                                                    }}
                                                    size='sm'
                                                />
                                            </FormControl>
                                        </Container>
                                    </Container>
                                    <Container>
                                        <Container display="flex" justifyContent="space-between">
                                            <FormControl isRequired>
                                                <FormLabel>Level</FormLabel>
                                                <Box >
                                                    <Select
                                                        onChange={(e)=>{
                                                        setLevel(e.target.value)
                                                    }}>
                                                        <option value=''>Select</option>
                                                        <option value='floor-one'>1st Floor</option>
                                                        <option value='floor-two'>2nd Floor</option>
                                                        <option value='floor-three'>3rd Floor</option>
                                                        <option value='floor-four'>4th Floor</option>
                                                        <option value='floor-five'>5th Floor</option>
                                                        <option value='floor-six'>6th Floor</option>
                                                        <option value='floor-seven'>7th Floor</option>
                                                        <option value='floor-eight'>8th Floor</option>
                                                        <option value='floor-nine'>9th Floor</option>
                                                        <option value='floor-ten'>10th Floor</option>
                                                        <option value='basement'>Basement</option>
                                                        <option value='ground'>Ground</option>
                                                        <option value='land'>Land</option>
                                                        <option value='lower-ground'>Lower Ground</option>
                                                        <option value='mezzanine'>Mezzanine</option>
                                                        <option value='upper-ground'>Upper Ground</option>
                                                    </Select>
                                                </Box>
                                            </FormControl>
                                        </Container>
                                        <Container display="flex" justifyContent="space-between">
                                            <FormControl>
                                                <FormLabel>Package</FormLabel>
                                                <Box >
                                                    <Select
                                                        onChange={(e)=>{
                                                            setPackage(e.target.value)
                                                        }}
                                                    >
                                                        <option value='option1'>Option 1</option>
                                                        <option value='option2'>Option 2</option>
                                                        <option value='option3'>Option 3</option>
                                                    </Select>
                                                </Box>
                                            </FormControl>
                                        </Container>
                                        <Container >
                                            <Flex direction={{ base: "column", md: "row" }} justifyContent="space-between" gap="2" alignItems="center">
                                                <FormControl >
                                                    <FormLabel>North</FormLabel>
                                                    <Input  onChange={(e)=>{
                                                        setNorthSrFeet(e.target.value)
                                                    }}  />
                                                </FormControl>
                                                <FormControl >
                                                    <FormLabel>"</FormLabel>
                                                    <Input  onChange={(e)=>{
                                                        setNorthFeet(e.target.value)
                                                    }} />
                                                </FormControl>
                                            </Flex>
                                            <Flex direction={{ base: "column", md: "row" }} justifyContent="space-between" gap="2" alignItems="center">
                                                <FormControl >
                                                    <FormLabel>Sout</FormLabel>
                                                    <Input  onChange={(e)=>{
                                                        setSouthSrFeet(e.target.value)
                                                    }}  />
                                                </FormControl>
                                                <FormControl >
                                                    <FormLabel>"</FormLabel>
                                                    <Input  onChange={(e)=>{
                                                        setSouthFeet(e.target.value)
                                                    }} />
                                                </FormControl>
                                            </Flex>
                                        </Container>
                                        <Container >
                                            <Flex direction={{ base: "column", md: "row" }} justifyContent="space-between" gap="2" alignItems="center">
                                                <FormControl >
                                                    <FormLabel>Sqr. Ft.</FormLabel>
                                                    <Input
                                                        value={srFeet}
                                                        onChange={(e)=>{
                                                            setSrFeet(e.target.value)
                                                        }} />
                                                </FormControl>
                                                <FormControl >
                                                    <FormLabel>Per Sqr. Ft.</FormLabel>
                                                    <Input
                                                        value={perSrFeet}
                                                        onChange={(e)=>{
                                                            setPerSrFeet(e.target.value)
                                                        }} />
                                                </FormControl>
                                            </Flex>
                                        </Container>
                                        <Container >
                                            <FormControl>
                                                <FormLabel>Status</FormLabel>
                                                <Box >
                                                    <Select
                                                    >
                                                        <option disabled={true} selected={true} value='unsold'>Un Sold</option>
                                                        <option value='sold'>Sold</option>
                                                    </Select>
                                                </Box>
                                            </FormControl>
                                            <Center>
                                                <Divider>

                                                </Divider>
                                            </Center>
                                            <FormControl>
                                                <Input onChange={(e)=>{
                                                    setStatusRemarkes(e.target.value)
                                                }} />
                                            </FormControl>
                                        </Container>
                                    </Container>
                                    <Container gridColumn={{ md: "span 2", lg:"span 1" }}>
                                        <Container >
                                            <FormControl >
                                                <FormLabel>Block</FormLabel>
                                                <Input onChange={handleChange} placeholder='' />
                                            </FormControl>
                                        </Container>

                                        <Center mb="10px" height='50px'>
                                            <Divider orientation='vertical' />
                                        </Center>

                                        <Container >
                                            <Flex direction={{ base: "column", md: "row" }} justifyContent="space-between" gap="2" alignItems="center">
                                                <FormControl >
                                                    <FormLabel>East</FormLabel>
                                                    <Input onChange={(e)=>{
                                                        setEastSrFeet(e.target.value)
                                                    }} />
                                                </FormControl>
                                                <FormControl >
                                                    <FormLabel>"</FormLabel>
                                                    <Input onChange={(e)=>{
                                                        setEastFeet(e.target.value)
                                                    }} />
                                                </FormControl>
                                            </Flex>

                                            <Flex direction={{ base: "column", md: "row" }} justifyContent="space-between" gap="2" alignItems="center">
                                                <FormControl >
                                                    <FormLabel>West'</FormLabel>
                                                    <Input onChange={(e)=>{
                                                        setWestSrFeet(e.target.value)
                                                    }} />
                                                </FormControl>
                                                <FormControl isRequired>
                                                    <FormLabel>"</FormLabel>
                                                    <Input onChange={(e)=>{
                                                        setWestFeet(e.target.value)
                                                    }} />
                                                </FormControl>
                                            </Flex>
                                        </Container>
                                        <Container >
                                            <Flex direction={{ base: "column", md: "row" }} justifyContent="space-between" gap="2" alignItems="center">
                                                <FormControl >
                                                    <FormLabel>Size</FormLabel>
                                                    <Input
                                                        value={merlaSize}
                                                        onChange={(e)=>{
                                                        setMerlaSize(e.target.value)
                                                    }} />
                                                </FormControl>
                                                <FormControl >
                                                    <FormLabel>MNL Size</FormLabel>
                                                    <Input
                                                        value={mnlSize}
                                                        onChange={(e)=>{
                                                        setMnlSize(e.target.value)
                                                    }} />
                                                </FormControl>
                                            </Flex>

                                            <Flex direction={{ base: "column", md: "row" }} justifyContent="space-between" gap="2" alignItems="center">
                                                <FormControl >
                                                    <FormLabel>Measurement Type</FormLabel>
                                                    <Box >
                                                        <Select
                                                            onChange={(e)=>{
                                                                setMeasurementType(e.target.value)
                                                            }}
                                                        >
                                                            <option value=''>Select</option>
                                                            <option selected={true} value='marla'>Marla</option>
                                                        </Select>
                                                    </Box>
                                                </FormControl>
                                            </Flex>
                                        </Container>
                                    </Container>
                                </Grid>
                                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }} gap="20px">
                                    <FormControl>
                                        <Button
                                            fontSize="10px"
                                            type="submit"
                                            bg="red.500"
                                            mb="20px"
                                            color="white"
                                            mt="20px"
                                            _hover={{
                                                bg: "red.500",
                                            }}
                                            _active={{
                                                bg: "red.400",
                                            }}
                                            onClick={handelSubmit}
                                        >
                                            {buttonText}
                                        </Button>
                                    </FormControl>
                                </Grid>
                            </Box>
                        </CardBody>
                    </Card>
                </form>
            </Flex>
        </>
    );
}

export default AddForm;

import axios from "./index";

class PlotApi {
  static List = (config) => {
    return axios.get(`${base}`, config);
  };

  static Add = (data, config) => {
    return axios.post(`${base}`, data, config);
  };

  static Delete = (id, config) => {
    return axios.delete(`${base}/${id}`, config);
  };

  static Detail = (id, config) => {
    return axios.get(`${base}/${id}`, config);
  };

  static UpdateProject = (id, data, config) => {
    return axios.put(`${base}/${id}`, data, config);
  };

}

let base = "plots";

export default PlotApi;
